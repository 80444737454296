import { Controller } from "stimulus"
const api_url = `${window.location.origin}/api/v1/`
const app_name = "congressional"

export default class extends Controller {
  static targets = ["applicantd", "applicantr", "applicantu", "inputd", "inputr", "inputu"]
  initialize() {
    this.appname = this.inputdTarget.dataset.info
  }
  pick_d() {
    this.load_d()
  }
  pick_r() {
    this.load_r()
  }
  pick_u() {
    this.load_u()
  }
  load(party) {
    const my_url = this.url + this.app + `/${party}.json`
    //fetch(my_url +  `/${party}.json`)
    fetch(my_url)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }
  load_d() {
    //const appname = this.inputdTarget.dataset.info
    const party = "d"
    const my_url = `${api_url}${this.appname}/${party}.json`
    const el = this.applicantdTarget
    fetch(my_url)
      .then(response => response.json())
      .then(data => {
        el.textContent = this.display_blurb(data)
        this.inputdTarget.value = data.id 
      })
  }
  load_r() {
    const party = "r"
    const my_url = `${api_url}${this.appname}/${party}.json`
    const el = this.applicantrTarget
    fetch(my_url)
      .then(response => response.json())
      .then(data => {
        el.textContent = this.display_blurb(data)
        this.inputrTarget.value = data.id 
      })
  }
  load_u() {
    const party = "u"
    const my_url = `${api_url}${this.appname}/${party}.json`
    const el = this.applicantuTarget
    fetch(my_url)
      .then(response => response.json())
      .then(data => {
        el.textContent = this.display_blurb(data)
        this.inputuTarget.value = data.id 
      })
  }
  display_blurb(data) {
    return `district: ${data.district}, party: ${data.party_affiliation}`
  }
}
