// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// application.js
require("trix")
require("@rails/actiontext")
// for that one image
require("magnific-popup")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
window.jQuery = $;
window.$ = $;
$(function(){
    // Plain jquery
    console.log("page loaded");
    // initialize magnific popup
    $('.image-link').magnificPopup({
        type:'image',
        closeBtnInside: false,
        closeOnContentClick: true,
        overflowY: 'scroll'
      });
    window.jQuery = $;
    window.$ = $;
})

import 'bootstrap'
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})


import 'controllers'
